{
  "name": "bdo-webpanel",
  "version": "1.7.40",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "prebuild": "npm --no-git-tag-version version patch",
    "build": "ng build && npm run sentry:sourcemaps",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "test:dependency": "owasp-dependency-check --project \"bdo-mobile-webpanel\" --scan \"package-lock.json\" --exclude \"dependency-check-bin\" --out \"owasp\" --format XML",
    "test:dependency:json": "owasp-dependency-check --project \"bdo-mobile-webpanel\" --scan \"package-lock.json\" --exclude \"dependency-check-bin\" --out \"owasp\" --format JSON",
    "test:dependency:html": "owasp-dependency-check --project \"bdo-mobile-webpanel\" --scan \"package-lock.json\" --exclude \"dependency-check-bin\" --out \"owasp\" --format HTML",
    "build-light-theme": "sass src/assets/themes/tailwind/tailwind-light/theme.scss:src/assets/themes/material/light/theme.css",
    "build-dark-theme": "sass src/assets/themes/tailwind/tailwind-dark/theme.scss:src/assets/themes/material/dark/theme.css",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org mobiletract --project panel-bdo-mobile ./dist/bdo-webpanel && sentry-cli sourcemaps upload --org mobiletract --project panel-bdo-mobile ./dist/bdo-webpanel"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@angular/service-worker": "^18.2.12",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.7.1",
    "@fortawesome/free-brands-svg-icons": "^6.7.1",
    "@fortawesome/free-regular-svg-icons": "^6.7.1",
    "@fortawesome/free-solid-svg-icons": "^6.7.1",
    "@mobiletract/auth-service-angular-client": "^1.9.0-dev2",
    "@mobiletract/mobiletract-packaging-angular-api": "^1.0.16",
    "@mobiletract/ngx-generic-table": "^0.0.69",
    "@mobiletract/reporting-service-angular-client": "^1.3.0-dev1",
    "@mobiletract/wasterecord-service-angular-client": "1.10.0-dev12",
    "@ngrx/component-store": "^18.1.1",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@sentry/angular-ivy": "^7.120.0",
    "@sentry/cli": "^2.39.1",
    "angular-google-tag-manager": "^1.10.0",
    "chart.js": "^4.4.6",
    "date-fns": "^4.1.0",
    "date-fns-tz": "^3.2.0",
    "flag-icons": "^7.2.3",
    "jasmine-marbles": "^0.9.2",
    "keycloak-angular": "^15.0.0",
    "keycloak-js": "^22.0.5",
    "ngx-gravatar": "^13.0.0",
    "ngx-pl-validators": "^1.0.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.9",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.3",
    "validate-polish": "^2.1.40",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "18.4.2",
    "@angular-eslint/eslint-plugin": "18.4.2",
    "@angular-eslint/eslint-plugin-template": "18.4.2",
    "@angular-eslint/schematics": "^18.4.2",
    "@angular-eslint/template-parser": "18.4.2",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@ngrx/eslint-plugin": "^18.1.1",
    "@types/jasmine": "^5.1.4",
    "@types/mocha": "^10.0.10",
    "@types/node": "^22.10.0",
    "@typescript-eslint/eslint-plugin": "^8.16.0",
    "@typescript-eslint/parser": "^8.16.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.15.0",
    "jasmine-core": "~5.4.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "owasp-dependency-check": "^0.0.24",
    "postcss": "^8.4.49",
    "protractor": "~7.0.0",
    "tailwindcss": "^3.4.15",
    "ts-node": "~10.9.2",
    "typescript": "5.5.4"
  },
  "optionalDependencies": {
    "@rollup/rollup-linux-x64-gnu": "^4.34.8"
  }
}
